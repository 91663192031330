import React from "react"
import {useTranslation} from "react-i18next"

import './industry.component.less';

import IndasrtialMain from "../../assets/img/industry/indastrial_main.jpg";
import IndastrialSmall from "../../assets/img/industry/indasrtial_small.jpg";
import Rectangle from "../../assets/img/industry/rectangle.svg";
import {useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../seo";

const IndustryComponent = () => {
    const { t, i18n } = useTranslation();
    const { originalPath } = useI18next();

    return (
        <div>
            <Seo description={t("metaDescriptionIndustry")} lang={i18n.language} title={t("titleConnectIndustry")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="industry">
                <div className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span dangerouslySetInnerHTML={{__html: t("sustainabilityManagementPlat")}} /> <span className="main-block__title-purple" dangerouslySetInnerHTML={{__html: t("forIndustrialAnd")}} />
                        </div>
                        <div className="main-block__description">
                            <span dangerouslySetInnerHTML={{__html: t("cityScreenIndustryWide")}} />
                        </div>
                        <div
                            className="main-block__description main-block__description--others">
                            <strong><span dangerouslySetInnerHTML={{__html: t("cityScreenIndustryEnvironmental")}} /></strong>
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={IndasrtialMain}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                    className="main-block__small-img"
                                    src={IndastrialSmall}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--top-padding wrapper_block__block--no-padding-bottom wrapper_block--yellow wrapper_block__block--no-border title-and-img title-and-img--small-padding">
                    <div className="title-and-img__title">
                        <div className="title-and-img__title--subtitle font-size-16">
                            <strong><span dangerouslySetInnerHTML={{__html: t("cityScreenIndustryNew")}} /></strong><span dangerouslySetInnerHTML={{__html: t("newApproachToTreat")}} />
                        </div>
                        <div className="title-and-img__title--flex font-size-3-5-rem"><span dangerouslySetInnerHTML={{__html: t("cityScreenIndustrySolutions")}} /></div>
                    </div>
                    <div className="title-and-img__img"><img alt="" src={Rectangle}/>
                    </div>
                </div>

                <div
                    className="wrapper_block wrapper_block__block wrapper_block--yellow wrapper_block__block--no-border two-col two-col--non-padding wrapper_block--padding-bottom not-border-top-ipad">
                    <div className="two-col__col two-col__col--2 two-col__col--3">
                        <div
                            className="two-col__col-left two-col__col-left--non_border">
                            <div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("researchInvolvingCertified")}} /></div>
                            </div>
                            <div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("expertiseOfAlready")}} /></div>
                            </div>
                            <div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("definingTheRole")}} /></div>
                            </div>
                        </div>
                        <div className="two-col__col-right">
                            <div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("developmentAndImplementation")}} /></div>
                            </div>
                            <div className="two-col__section">
                                <div className="two-col__title"><span dangerouslySetInnerHTML={{__html: t("forecastingOfEmissionsDispersion")}} /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--top-padding wrapper_block__block--no-border title-and-img">
                    <div className="borders-block__col">
                    <div className="title-and-img__title">
                        <span dangerouslySetInnerHTML={{__html: t("cityAirSolutionsForIndustrialSector")}} />
                    </div>
                    <div className="title-and-img__description">
                        <span dangerouslySetInnerHTML={{__html: t("anyIndustrialEntityDue")}} />
                        <strong><span dangerouslySetInnerHTML={{__html: t("anyIssue")}} /></strong>
                    </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span dangerouslySetInnerHTML={{__html: t("airQualityIoTSolution")}} />
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("selfMonitoring")}} /> </strong>
                                </div>
                                <div className="borders-block__col--point">
                                    <span dangerouslySetInnerHTML={{__html: t("inOurCompactAndFully")}} />
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("dataVisualization")}} /> </strong>
                                    <span dangerouslySetInnerHTML={{__html: t("forCitizensAndAuthorities")}} />
                                </div>
                                <div className="borders-block__col--point">
                                    <span dangerouslySetInnerHTML={{__html: t("cityScreenProvidesCalculation")}} />
                                </div>
                            </div>
                            <div className="borders-block__hr--horizontal borders-block__hr--mobile" />
                        </div>
                        <div className="borders-block__hr" />
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">3
                                </div>
                                <div
                                    className="borders-block__col--title"><span dangerouslySetInnerHTML={{__html: t("dispersionOfEmissionsFrom")}} />
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("pollutionSources")}} /></strong>
                                </div>
                                <div className="borders-block__col--point">
                                    <span dangerouslySetInnerHTML={{__html: t("plumeVisualizationIs")}} />
                                </div>
                            </div>
                            <div
                                className="borders-block__hr--horizontal" />
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">4</div>
                                <div
                                    className="borders-block__col--title">
                                    <strong> <span dangerouslySetInnerHTML={{__html: t("occupationalHealth")}} /> </strong>
                                    <span dangerouslySetInnerHTML={{__html: t("andSafety")}} />
                                </div>
                                <div
                                    className="borders-block__col--point"><span dangerouslySetInnerHTML={{__html: t("denseAirQualityMonitoring")}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="link_and__end-block wrapper_block--padding-bottom wrapper_block wrapper_block__block wrapper_block__block--no-border">
                    <span className="link_and__mail"
                          dangerouslySetInnerHTML={{__html: t("ToGetADetailedInformationAbout")}}/>
                </div>
            </div>
        </div>)
}

IndustryComponent.propTypes = {}

IndustryComponent.defaultProps = {}

export default IndustryComponent
